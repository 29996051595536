import React from "react"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"
import { graphql } from "gatsby"

const Blog = ({ location, data }) => {
  const {
    allBlogJson: { nodes: blogs },
  } = data
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="Blog"
          pagepath={location.pathname}
          pagedesc="Blog List | PARKLoT offers a Twitter campaign tool that can be run as many times as you want for a monthly fee."
        />
        <main className="information">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16">
            <div className="text-center">
              <h1 className="text-white font-bold">PARKLoT Blog</h1>
            </div>
          </section>
          <section className="md:py-16 pb-16 pt-4 l-container">
            <ul className="md:flex md:flex-wrap blog-list__container">
              {blogs.map((blog, index) => {
                return (
                  <li key={index}>
                    <a className="blog-list__link" href={blog.href}>
                      <img
                        src={blog.imageUrl}
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt={blog.alt}
                      />
                      <div className="mt-4 mb-2">
                        <span className="px-3 py-1 bg-primary text-white rounded text-xs inline-block">
                          {blog.type}
                        </span>
                      </div>
                      <p className="font-bold mb-3">{blog.title}</p>
                      <div className="blog-list__meta">
                        <p className="text-xs mb-1">{blog.date}</p>
                        <p className="text-xs font-bold">
                          This article can be read in {blog.minutes}
                        </p>
                      </div>
                    </a>
                  </li>
                )
              })}
            </ul>
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query GetBlogs {
    allBlogJson {
      nodes {
        id
        title
        href
        imageUrl
        alt
        updateDate
        releaseDate
        type
        minutes
      }
    }
  }
`

export default Blog
